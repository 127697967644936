import EmptyForm from './EmptyForm.vue';
import MedJournalForm from './MedJournalForm.vue';
import StatsForm from './StatsForm.vue';
import GeneratedForm from './GeneratedForm.vue';

export default {
  EmptyForm,
  MedJournalForm,
  StatsForm,
  GeneratedForm,
};
