import { convertDateToISO } from '@/utils/helpers.js';

export function base(filters, { fields } = {}) {
  if (!fields || fields.length === 0) return {};

  if (fields)
    return Object.fromEntries(
      Object.entries(filters)
        .map(([name, value]) => {
          if (fields.includes(name)) return [name, value];

          if (name === 'dateStart' && fields.includes('dateRange')) {
            const time = fields.includes('timeRange')
              ? filters.timeStart
              : undefined;
            return [name, convertDateToISO(value, time)];
          }

          if (name === 'dateEnd' && fields.includes('dateRange')) {
            const time = fields.includes('timeRange')
              ? filters.timeFinish
              : undefined;
            return [name, convertDateToISO(value, time || '23:59', '59:999')];
          }

          if (name === 'medicGroupIds' && fields.includes('region')) {
            return [name, value];
          }
        })
        .filter(item => item),
    );
}

export function medjournal(filters, _, { byHostname, byFiles }) {
  let data = [];
  const dateStart = convertDateToISO(filters.dateStart);
  const dateEnd = convertDateToISO(filters.dateEnd, '23:59', '59:999');
  const { enableGraphSign, organization, hostnames } = filters;
  if (!byHostname) {
    data = filters.organizations.map(item => ({
      orgId: item,
      dateStart,
      dateEnd,
      enableGraphSign,
    }));
  } else if (byHostname && !byFiles) {
    data = {
      orgId: organization,
      hostnames,
      dateStart,
      dateEnd,
      enableGraphSign,
    };
  } else if (byHostname && byFiles) {
    data = hostnames.map(item => ({
      orgId: organization,
      hostnames: [item],
      dateStart,
      dateEnd,
      enableGraphSign,
    }));
  }

  if (data.length === 1) {
    return data[0];
  }

  return data;
}

export default {
  base,
  medjournal,
};
