<template lang="pug">
v-row
  v-col.pt-0
    v-row
      v-col(cols="8")
        OrganizationPicker(
          key="1"
          v-if="byHostname"
          v-model="value.organization"
          :items="organizations"
          :error-messages="getValidationErrors('value.organization')"
        )
        OrganizationPicker(
          key="2"
          v-else
          multiple
          v-model="value.organizations"
          :items="organizations"
          :maxSelected="limits.orgsInBatch"
          :error-messages="getValidationErrors('value.organizations')"
        )
      v-col(cols="4" forLine)
        v-checkbox(
          v-model="byHostname"
          label="По точкам осмотра"
        )
    v-row(v-if="byHostname")
      v-col
        SelectHostname(
          v-model="value.hostnames"
          :items="hostnames"
          :disabled="!value.organization"
          :organizations="value.organization"
          :error-messages="getValidationErrors('value.hostnames')"
          :allSelectedToNull="false"
          orgHostBinded
        )
      v-col(cols="4" forLine)
        v-checkbox(
          v-if="byHostname"
          v-model="byFiles"
          label="Разбить точки по файлам"
        )
    v-row
      v-col(cols="8")
        DatePicker(
          v-if="!byRange"
          label="Месяц"
          first-day-of-week="1"
          typeTextField="text"
          typeDatePicker="month"
          :readonly="true"
          :value="month"
          @input="changeMonth"
          :error-messages="getValidationErrors('value.dateStart')"
        )
        DateRangePicker(
          v-else
          label="Диапазон дат"
          placeholder="Выберите организацию"
          :value="value"
          @input="$emit('input', {...value, ...$event})"
          item-from="dateStart"
          item-to="dateEnd"
          readonly
          :maxRange="31"
          clearable
          :error-messages="validDateRange ? 'Обязательное заполнение' : null"
        )
      v-col(cols="4" forLine)
        v-checkbox(
          v-model="byRange"
          label="Указать период"
        )
    v-row
      v-col(cols="8")
        v-checkbox(
          single-line
          hide-details
          v-model="value.enableGraphSign"
          label="Включить графическую подпись"
        )
      slot
</template>

<script>
import validationMixin from '@/utils/validation';
import { required, requiredIf } from 'vuelidate/lib/validators';

import { DateTime } from 'luxon';
import { createForm } from './FormMixin';
import { mapGetters } from 'vuex';
import SelectHostname from '@/components/controls/SelectHostname.vue';
import DateRangePicker from '@/components/controls/DateRangePicker';
import DatePicker from '@/components/controls/DatePicker';
import OrganizationPicker from '@/components/controls/OrganizationPicker';

export default createForm(validationMixin).extend({
  components: {
    OrganizationPicker,
    SelectHostname,
    DateRangePicker,
    DatePicker,
  },
  validations() {
    return {
      value: {
        organization: {
          required: requiredIf(() => this.byHostname),
        },
        hostnames: {
          required: requiredIf(() => this.byHostname),
        },
        organizations: {
          required: requiredIf(() => !this.byHostname),
          max: value =>
            !this.byHostname && value
              ? value?.length <= this.limits.orgsInBatch
              : true,
        },
        dateStart: { required },
        dateEnd: { required },
      },
    };
  },
  data() {
    return {
      validationMessages: {
        max: 'Вы выбрали слишком много организаций',
      },
      byHostname: false,
      byFiles: false,
      byRange: false,
    };
  },

  watch: {
    byHostname(isByHostname) {
      this.$v.$reset();

      if (isByHostname) {
        if (!this.value.organizations) return;
        // If check "by hostname"
        this.set(
          'organization',
          this.value.organizations[0] || this.value.organizations[0]?.id,
        );
      } else {
        this.set(
          'organizations',
          this.value.organization ? [this.value.organization] : [],
        );
      }
    },
  },

  computed: {
    ...mapGetters(['organizations', 'hostnames']),

    month() {
      if (!this.value.dateStart) return null;
      const [year, month] = this.value.dateStart.split('-');

      return year + '-' + month;
    },

    validDateRange() {
      return (
        this.getValidationErrors('value.dateStart').length !== 0 ||
        this.getValidationErrors('value.dateEnd').length !== 0
      );
    },
  },
  methods: {
    initFields(filters) {
      const previousMonth = DateTime.local()
        .minus({ month: 1 })
        .startOf('month')
        .startOf('day');

      return {
        organization: filters.orgId,
        hostnames: null,
        enableGraphSign: false,
        dateStart: previousMonth.toFormat('yyyy-MM-dd'),
        dateEnd: previousMonth.endOf('month').toFormat('yyyy-MM-dd'),
      };
    },
    changeMonth(value) {
      const [year, month] = value.split('-');
      const date = DateTime.local(Number(year), Number(month), 1);
      this.set({
        ...this.value,
        dateStart: date.toFormat('yyyy-MM-dd'),
        dateEnd: date.endOf('month').toFormat('yyyy-MM-dd'),
      });
    },
    getParams() {
      return {
        byHostname: this.byHostname,
        byFiles: this.byFiles,
      };
    },
  },
});
</script>
