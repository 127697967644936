<template lang="pug">
v-row
  v-col(cols="6" v-if="hasField('dateRange')")
    DateRangePicker(
        label="Диапазон дат"
        placeholder="Выберите организацию"
        :value="value"
        @input="$emit('input', {...value, ...$event})"
        item-from="dateStart"
        :maxRange="92"
        clearable
        item-to="dateEnd"
        readonly
        :error-messages="validDateRange ? 'Обязательное заполнение' : null"
      )
  v-col(cols="6" v-if="hasField('orgId')")
    OrganizationPicker(
        v-model="value.orgId"
        :items="organizations"
        :error-messages="getValidationErrors('value.orgId')"
      )
  v-col(cols="6" v-if="hasField('region')")
    MultipleSelect(
      label="Регион"
      placeholder="Выберите регион"
      v-model="value.medicGroupIds"
      :items="medicInnerGroups"
      item-value="id"
      item-text="name"
      :error-messages="getValidationErrors('value.medicGroupIds')"
    )

  template(v-if="hasField('timeRange')")
    v-col(cols="3")
      v-text-field(
        label="Начальное время"
        type="time"
        v-model="value.timeStart"
        :error-messages="getValidationErrors('value.timeStart')"
      )
    v-col(cols="3")
      v-text-field(
        label="Конечное время"
        type="time"
        v-model="value.timeFinish"
        :error-messages="getValidationErrors('value.timeFinish')"
      )

  v-col(cols="8")
    v-checkbox(
      v-if="hasField('includeTest')"
      v-model="value.includeTest"
      label="Включить тестовые осмотры"
    )
    v-checkbox(
      v-if="hasField('includeTechErrors')"
      v-model="value.includeTechErrors"
      label="Учитывать осмотры с техническими ошибками"
    )
  slot
</template>

<script>
import validationMixin from '@/utils/validation';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { createForm } from './FormMixin';
import DateRangePicker from '@/components/controls/DateRangePicker';
import OrganizationPicker from '@/components/controls/OrganizationPicker';
import MultipleSelect from '@/components/controls/MultipleSelect';

export default createForm(validationMixin).extend({
  components: {
    DateRangePicker,
    OrganizationPicker,
    MultipleSelect,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['organizations', 'medicInnerGroups']),
    hasField() {
      return name => this.fields.includes(name);
    },
    validDateRange() {
      return (
        this.getValidationErrors('value.dateStart').length !== 0 ||
        this.getValidationErrors('value.dateEnd').length !== 0
      );
    },
  },
  validations() {
    const value = {};

    if (this.hasField('orgId')) value.orgId = { required };
    if (this.hasField('region')) value.medicGroupIds = { required };
    if (this.hasField('includeTest')) value.includeTest = { required };
    if (this.hasField('includeTechErrors'))
      value.includeTechErrors = { required };

    if (this.hasField('dateRange')) {
      value.dateStart = { required };
      value.dateEnd = { required };
    }

    if (this.hasField('timeRange')) {
      value.timeStart = { required };
      value.timeFinish = { required };
    }

    return { value };
  },
});
</script>
