<template lang="pug">
  div
    v-menu(v-if="formats.length > 1")
      template(v-slot:activator="{ on: menu, attrs }")
        v-btn(
          block
          color="primary"
          v-on="{ ...menu }"
        ) Сформировать
      v-list
        v-list-item(
          v-for="format in formats"
          :key="format"
          @click="$emit('click', format)"
        )
          v-list-item-title {{actions(format)}}
    v-btn(
      v-else
      block
      color="primary"
      @click="$emit('click', formats[0])"
    ) Сформировать
</template>

<script>
const ACTIONS = {
  csv: 'Сформировать отчет',
  xlsx: 'Сформировать отчет .xlsx',
  pdf: 'Сформировать отчет .pdf',
  json: 'Построить график',
};
export default {
  props: {
    formats: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    actions(format) {
      return format => ACTIONS[format] || format;
    },
  },
};
</script>
