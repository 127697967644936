import Vue from 'vue';
import mixins from '@/utils/mixins';

const FormMixin = Vue.extend().extend({
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    limits: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.set(this.initFields(this.value));
  },
  methods: {
    /**
     * Set values of form fields
     */
    set(key, value) {
      if (typeof key !== 'string') {
        this.$emit('input', key);
      } else {
        this.$emit('input', { ...this.value, [key]: value });
      }
    },
    /**
     * Validate values of form fields
     */
    validateFields() {
      // If current companent has vuelidate mixin
      if (this.$v && this.$v.$touch) {
        this.$v.$touch();
        return !this.$v.$invalid;
      }
      // In other cases
      return true;
    },
    /**
     * This method can be redefined in component for adding specific filter-initial logic
     */
    initFields(value) {
      return value;
    },
    getParams() {
      return {};
    },
  },
});

export default FormMixin;
export const createForm = (...args) => mixins(FormMixin, ...args);
