<template lang="pug">
v-card.mb-3
  template(v-if="$root.isLoading('reports_list')")
    v-toolbar(flat)
      v-btn(icon @click="changeReport()")
        v-icon fa-arrow-left
      v-toolbar-title Выгрузка отчета
    v-progress-linear(indeterminate)
    v-card-text.text-center Загрузка доступных отчетов
  template(v-else)
    v-toolbar(flat)
      v-toolbar-title.mr-3
        v-btn(icon @click="changeReport()")
          v-icon fa-arrow-left
        span Выгрузка отчета
      span(v-if="availableReports.length === 1") – {{availableReports[0].name}}
      v-autocomplete(
        v-else
        single-line
        hide-details
        :items="availableReports"
        item-value="id"
        item-text="name"
        :value="id"
        @input="changeReport"
        )
    v-divider
    v-card-text.text-center(v-if="!report")
      div Такого отчета не существует
      v-btn.mt-2(
        color="primary"
        depressed
        :to="{name:'reports'}"
        ) Назад
    v-card-text(v-else)
      component(
        :is="component"
        v-bind="{...report}"
        v-model="filters"
        ref="form"
      )
        v-col(cols="4" sm="4" alignSelf="end")
          ExportButton(:formats="report.availableFormats" @click="onSubmit")
  v-divider
</template>

<script>
import forms from '@/views/reports/forms';
import filtersProcessing from '@/views/reports/filtersProcessing';
import ExportButton from '@/views/reports/components/ExportButton';
import { mapGetters, mapActions } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';

export default {
  components: {
    ExportButton,
  },
  props: {
    changeReport: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('REPORTS', ['availableReports']),
    ...mapGettersMutations('REPORTS', { filters: 'filters' }),

    id() {
      return this.$route.params.id;
    },
    report() {
      return this.availableReports.find(({ id }) => this.id === id);
    },
    component() {
      return forms[this.report.form || 'EmptyForm'];
    },
  },
  methods: {
    ...mapActions('REPORTS', ['export']),
    async onSubmit(format) {
      const form = this.$refs.form;
      if (!form.validateFields()) return;

      const reportName = this.report.name;

      const filtersName = this.report.filters || 'base';
      const filters = filtersProcessing[filtersName](
        this.filters,
        this.report,
        form.getParams(),
      );

      try {
        await this.export({ id: this.id, format, filters });
      } catch (error) {
        console.error(error);
        this.$notify({
          group: 'error',
          type: 'info',
          title: `Произошла ошибка при выгрузке отчета "${reportName}". Попробуйте позже.`,
          text: '',
        });
      }
    },
  },
};
</script>
