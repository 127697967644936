<template lang="pug">
v-row
  v-col(cols="6")
    OrganizationPicker(
      v-model="value.orgIds"
      :items="organizations"
      multiple
      can-select-all
      :virtualized="false"
      :error-messages="getValidationErrors('value.orgIds')"
      :maxSelected="limits.orgsInBatch"
    )
  v-col(cols="6")
    DateRangePicker(
        label="Диапазон дат"
        placeholder="Выберите организацию"
        :value="value"
        @input="$emit('input', {...value, ...$event})"
        item-from="dateStart"
        item-to="dateEnd"
        readonly
        :maxRange="92"
        clearable
        :error-messages="validDateRange ? 'Обязательное заполнение' : null"
      )
  v-col(cols="6")
    SelectType(
      v-model="value.inspectionTypes"
      :availableTypes="availableTypes"
      :error-messages="getValidationErrors('value.inspectionTypes')"
    )
  v-col(cols="6" )
    v-select(
      label="Тип допуска"
      placeholder="Выберите тип допуска"
      v-model="value.inspectionResults"
      :items="results"
    )
  v-col(cols="8")
    v-checkbox(
      v-model="value.includeTest"
      label="Включить тестовые осмотры"
      hide-details
    )
    v-checkbox(
      v-model="value.includeTechErrors"
      label="Учитывать осмотры с техническими ошибками"
    )
  slot
</template>

<script>
import validationMixin from '@/utils/validation';
import { required, maxLength } from 'vuelidate/lib/validators';
import { DateTime } from 'luxon';
import SelectType from '@/components/controls/SelectType';
import { createForm } from './FormMixin';
import { mapGetters } from 'vuex';
import DateRangePicker from '@/components/controls/DateRangePicker';
import OrganizationPicker from '@/components/controls/OrganizationPicker';

export default createForm(validationMixin).extend({
  components: {
    SelectType,
    DateRangePicker,
    OrganizationPicker,
  },
  validations() {
    return {
      value: {
        orgIds: { required, max: maxLength(this.limits.orgsInBatch + 1) },
        dateStart: { required },
        dateEnd: { required },
        inspectionTypes: {
          required: value => value === null || value.length > 0,
        },
        includeTechErrors: { required },
      },
    };
  },
  data() {
    return {
      validationMessages: {
        max: 'Вы выбрали слишком много организаций',
      },
      availableTypes: null,
      results: [
        { value: null, text: 'Все типы допусков' },
        { value: true, text: 'Допущен' },
        { value: false, text: 'Не допущен' },
      ],
    };
  },
  computed: {
    ...mapGetters(['organizations']),
    validDateRange() {
      return (
        this.getValidationErrors('value.dateStart').length !== 0 ||
        this.getValidationErrors('value.dateEnd').length !== 0
      );
    },
  },
  watch: {
    'value.orgIds': {
      immediate: true,
      async handler(val) {
        this.availableTypes = await this.$store.dispatch(
          'getAvailableTypes',
          val,
        );

        if (this.value.inspectionTypes && this.availableTypes) {
          // if we have selected more types than are available to us,
          // we remove the types that are not available to us
          this.value.inspectionTypes = this.value.inspectionTypes.filter(item =>
            this.availableTypes.includes(item),
          );
        }
      },
    },
  },
  methods: {
    initFields() {
      return {
        dateStart: DateTime.local().toFormat('yyyy-MM-dd'),
        dateEnd: DateTime.local().toFormat('yyyy-MM-dd'),
      };
    },
  },
});
</script>
